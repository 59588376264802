




























































































































    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
.main_back{
 
    width:100%;
    background: white;
}
.main_back .hor{
    text-align:center;
    background: white;
    font-weight: 700;
    font-size: 14px;
}
.item-width{
    line-height:40px;
    background:rgb(195, 237, 243);
    width:48%;
}
