::v-deep .el-table th {
  padding: 0px ;
}
::v-deep .el-table thead {
  height: 15px !important;
  color: #474646;
  font-size: 12px;
}
::v-deep .el-table td {
  padding: 3px ;
  font-size: 11px;
}
.main_back {
  width: 100%;
  background: white;
}
.main_back .hor {
  text-align: center;
  background: white;
  font-weight: 700;
  font-size: 14px;
}
.item-width {
  line-height: 40px;
  background: #c3edf3;
  width: 48%;
}
